import Seo from "gatsby-plugin-wpgraphql-seo";
import React from "react";
import { DefaultWordPressPageProps } from "../../../interfaces/WordPress/DefaultWordPressPageProps";
import VTSContactTemplate from "../../04-templates/VTSContactTemplate/VTSContactTemplate";

const VTSContactPage = (
  props: DefaultWordPressPageProps
): JSX.Element | null => {
  if (!props.pageContext.id) {
    return null;
  }

  return (
    <>
      {props.page && <Seo post={props.page} />}

      <VTSContactTemplate pageId={props.pageContext.id} />
    </>
  );
};

export default VTSContactPage;
