/* eslint-disable @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any */

/**
 * Creates a type guard for a given enum.
 * The type guard will check if a provided value is part of the enum.
 */
export const isSomeEnum =
  <T>(e: T) =>
  (token: any): token is T[keyof T] =>
    Object.values(e).includes(token as T[keyof T]);
