import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { coverParent } from "../../../helpers/styles/coverParent";

export const useVTSContactBoxStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      marginTop: theme.spacing(-22),
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(-17),
      },
    },
    leftColumn: {
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-end",
      rowGap: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
    },
    rightColumn: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
    },
    slogan: {
      marginTop: theme.spacing(-2),
    },
    handWrittenSlogan: {
      "& > *": {
        "& > *": {
          marginBlockEnd: "0 !important",
        },
      },
    },
    contactInfo: {
      display: "inline-block",
      backgroundColor: theme.palette.secondary.main,
      padding: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
      },
      [theme.breakpoints.only("xs")]: {
        flexDirection: "column",
      },
    },
    address: {
      marginBottom: theme.spacing(2),
      [theme.breakpoints.only("sm")]: {
        paddingRight: theme.spacing(3),
      },
    },
    landscapeImage: {
      position: "relative",
      width: "28.75rem", // 460px
      height: "18.75rem", // 300px
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    portraitImage: {
      position: "relative",
      width: "18rem", // 288px
      height: "21rem", // 336px
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
    image: {
      ...coverParent,
    },
    link: {
      cursor: "pointer",
    },
  })
);
