import { Container, Typography } from "@material-ui/core";
import React from "react";
import { PostTypeInProject } from "../../../../config/wordPressConfig";
import { DefaultPageProps } from "../../../interfaces/WordPress/DefaultPageInterfaces";
import { PageLayout } from "../../../layouts/pageLayout/PageLayout";

const VTSDefaultTemplate = (props: DefaultPageProps): JSX.Element => {
  return (
    <PageLayout
      currentPostId={props.pageId}
      postType={PostTypeInProject.WpPage}
    >
      <Container className="noMargin">
        <Typography>Hello Default Page!</Typography>
      </Container>
    </PageLayout>
  );
};

export default VTSDefaultTemplate;
