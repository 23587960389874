import { Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";

export const useVTSTeamMemberCardStyles = makeStyles((theme: Theme) => {
  return createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflow: "visible",
      borderRadius: "0.5rem",
      boxShadow: `0px 4px 6px ${theme.palette.grey[100]}`,
      marginTop: theme.spacing(2),
      [theme.breakpoints.up("lg")]: {
        height: "88%",
      },
      [theme.breakpoints.down("md")]: {
        height: "90%",
      },
      [theme.breakpoints.down("sm")]: {
        height: "92%",
      },
    },
    image: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      borderRadius: "50%",
      marginTop: theme.spacing(-5),
      marginBottom: theme.spacing(1),
      boxShadow: `0px 4px 6px ${theme.palette.grey[100]}`,
    },
    name: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(0),

      [theme.breakpoints.up("lg")]: {
        minHeight: theme.spacing(4),
      },
      [theme.breakpoints.down("md")]: {
        minHeight: theme.spacing(4.5),
      },
      [theme.breakpoints.down("sm")]: {
        minHeight: theme.spacing(6.5),
      },
      [theme.breakpoints.down("xs")]: {
        minHeight: theme.spacing(0),
      },
    },
    links: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      padding: theme.spacing(1.5),
      color: theme.palette.grey[900],
    },
    icon: {
      width: theme.spacing(1),
    },

    info: {
      color: theme.palette.grey[600],
      textAlign: "center",
    },
    blankSpace: {
      padding: theme.spacing(1.5),
    },
  });
});
