import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSPublicationsTemplate = makeStyles((theme: Theme) =>
  createStyles({
    pageHeader: {
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        paddingBottom: theme.spacing(6),
      },
    },
  })
);
