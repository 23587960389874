import { Box } from "@material-ui/core";
import React from "react";
import {
  VTSServicesTabPanelProps,
  VTSServicesTabPanelStylesProps,
} from "./VTSServicesTabPanelInterfaces";
import { useVTSServicesTabPanelStyles } from "./VTSServicesTabPanelStyles";

export const VTSServicesTabPanel = (
  props: VTSServicesTabPanelProps
): JSX.Element => {
  const isActivePanel = props.activeTabIndex === props.index;
  const stylesProps: VTSServicesTabPanelStylesProps = {
    isActivePanel,
  };
  const classes = useVTSServicesTabPanelStyles(stylesProps);

  return (
    <Box className={classes.tabPanel}>{isActivePanel && props.children}</Box>
  );
};
