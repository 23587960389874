import { ReactNode } from "react";

export interface VTSServicesTabPanelProps {
  children: ReactNode;
  index: number;
  activeTabIndex: number;
}

export interface VTSServicesTabPanelStylesProps {
  isActivePanel: boolean;
}
