import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { mapWpImageInformation } from "../../../helpers/clientSide/WordPress/mapWpImageInformation";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import WordPressImage from "../../01-atoms/WordPressImage/WordPressImage";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";
import { VTSNetworkSectionProps } from "./VTSNetworkSectionInterfaces";
import { useVTSNetworkSectionStyles } from "./VTSNetworkSectionStyles";

const VTSNetworkSection = (props: VTSNetworkSectionProps): JSX.Element => {
  const classes = useVTSNetworkSectionStyles();

  const pageData = useSinglePage(props.pageId);
  const network = pageData?.pageFields?.services?.network;
  const title = network?.title || "";
  const description = network?.description || "";
  const image = mapWpImageInformation(network?.image);

  return (
    <Grid container={true} className={classes.wrapper}>
      <Grid
        item={true}
        container={true}
        xs={12}
        lg={6}
        className={classes.wrapperTitleDescription}
      >
        <VTSUnderlinedTitle
          titleRaw={title}
          typographyProps={{ variant: "h2", className: classes.title }}
          component="h3"
        />

        <Typography variant="body1">
          <SanitizeHTML html={description} />
        </Typography>
      </Grid>

      <Grid item={true} xs={12} lg={6} className={classes.wrapperImage}>
        {image && (
          <WordPressImage imageInformation={image} imageClass={classes.image} />
        )}
      </Grid>
    </Grid>
  );
};

export default VTSNetworkSection;
