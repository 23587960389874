import React, { FunctionComponent, useMemo } from "react";
import { Template } from "../../../../config/wordPressConfig";
import { isSomeEnum } from "../../../helpers/general/isSomeEnum";
import { DynamicWordPressPage } from "../../../interfaces/WordPress/DynamicPage";
import { WordpressPageContext } from "../../../interfaces/WordPress/wordpressContexts/WordpressPageContext";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import VTSAboutUsPage from "../../05-pages/VTSAboutUsPage/VTSAboutUsPage";
import VTSContactPage from "../../05-pages/VTSContactPage/VTSContactPage";
import VTSDataProtectionPage from "../../05-pages/VTSDataProtectionPage/VTSDataProtectionPage";
import VTSDefaultPage from "../../05-pages/VTSDefaultPage/VTSDefaultPage";
import VTSHomePage from "../../05-pages/VTSHomePage/VTSHomePage";
import VTSImprintPage from "../../05-pages/VTSImprintPage/VTSImprintPage";
import VTSNewsPage from "../../05-pages/VTSNewsPage/VTSNewsPage";
import VTSPublicationsPage from "../../05-pages/VTSPublicationsPage/VTSPublicationsPage";
import VTSServicesPage from "../../05-pages/VTSServicesPage/VTSServicesPage";

const isTemplateEnum = isSomeEnum(Template);

const SingleWpPage: FunctionComponent<
  DynamicWordPressPage<WordpressPageContext>
> = (props) => {
  const page = useSinglePage(props.pageContext.id);

  const templateMap: { [key in Template]: JSX.Element } = useMemo(
    () => ({
      [Template.Default]: (
        <VTSDefaultPage page={page} pageContext={props.pageContext} />
      ),
      [Template.Home]: (
        <VTSHomePage page={page} pageContext={props.pageContext} />
      ),
      [Template.Services]: (
        <VTSServicesPage page={page} pageContext={props.pageContext} />
      ),
      [Template.Publications]: (
        <VTSPublicationsPage page={page} pageContext={props.pageContext} />
      ),
      [Template.AboutUs]: (
        <VTSAboutUsPage page={page} pageContext={props.pageContext} />
      ),
      [Template.News]: (
        <VTSNewsPage page={page} pageContext={props.pageContext} />
      ),
      [Template.Contact]: (
        <VTSContactPage page={page} pageContext={props.pageContext} />
      ),
      [Template.DataProtection]: (
        <VTSDataProtectionPage page={page} pageContext={props.pageContext} />
      ),
      [Template.Imprint]: (
        <VTSImprintPage page={page} pageContext={props.pageContext} />
      ),
    }),
    [page, props.pageContext]
  );

  // determine which template to use
  let template: Template;

  if (!page?.pageFields) {
    template = Template.Default;
  } else {
    template = page.pageFields.template as Template;
  }

  if (isTemplateEnum(template)) {
    return templateMap[template as Template];
  }

  const errorMessage = `Template ${template || "unknown"} can't be rendered`;

  // eslint-disable-next-line no-console
  console.error(errorMessage, "Available templates:", Object.values(Template));

  return null;
};

export default SingleWpPage;
