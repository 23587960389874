import { TitlePosition } from "../../interfaces/TitlePosition";

export const mapWpTitlePositionToTitlePosition = (
  wpTitlePosition: string
): TitlePosition => {
  switch (wpTitlePosition) {
    case "beforeName":
      return TitlePosition.BeforeName;
    case "afterName":
      return TitlePosition.AfterName;
    default:
      return TitlePosition.BeforeName;
  }
};
