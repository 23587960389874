import { Box } from "@material-ui/core";
import React from "react";
import { Arrow } from "../../../__generated__/svgComponents/Arrow";
import { VTSCustomerJourneyArrowProps } from "./VTSCustomerJourneyArrowInterfaces";
import { useVTSCustomerJourneyArrowStyles } from "./VTSCustomerJourneyArrowStyles";

const VTSCustomerJourneyArrow = (
  props: VTSCustomerJourneyArrowProps
): JSX.Element => {
  const classes = useVTSCustomerJourneyArrowStyles(props);

  return (
    <Box className={classes.arrowWrapper}>
      <Arrow />
    </Box>
  );
};

export default VTSCustomerJourneyArrow;
