import { Container } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { PostTypeInProject } from "../../../../config/wordPressConfig";
import { DefaultPageProps } from "../../../interfaces/WordPress/DefaultPageInterfaces";
import { PageLayout } from "../../../layouts/pageLayout/PageLayout";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import VTSVerticalTitle from "../../01-atoms/VTSVerticalTitle/VTSVerticalTitle";
import VTSContactBox from "../../03-organisms/VTSContactBox/VTSContactBox";
import VTSCustomerJourneySection from "../../03-organisms/VTSCustomerJourneySection/VTSCustomerJourneySection";
import { CustomerJourneyPage } from "../../03-organisms/VTSCustomerJourneySection/VTSCustomerJourneySectionInterfaces";
import VTSPageHeader from "../../03-organisms/VTSPageHeader/VTSPageHeader";
import VTSPreFooterContact from "../../03-organisms/VTSPreFooterContact/VTSPreFooterContact";
import { useVTSContactTemplate } from "./VTSContactTemplateStyles";

const VTSContactTemplate = (props: DefaultPageProps): JSX.Element => {
  const classes = useVTSContactTemplate();
  const contactVerticalTitle = useSinglePage(props.pageId)?.pageFields?.header
    ?.verticalTitle;

  return (
    <PageLayout
      currentPostId={props.pageId}
      postType={PostTypeInProject.WpPage}
    >
      <Container className="noMargin">
        <VTSPageHeader
          pageId={props.pageId}
          showContactOptions={false}
          wrapperClass={classes.pageHeader}
        />
      </Container>

      <Container>
        <VTSVerticalTitle title={contactVerticalTitle || ""} />

        <Container className="noMargin">
          <VTSContactBox pageId={props.pageId} />
        </Container>

        <Container className={clsx("marginBottom", "paddingX")}>
          <VTSCustomerJourneySection
            pageId={props.pageId}
            showCardNumber={true}
            usedInPage={CustomerJourneyPage.Contact}
          />
        </Container>
      </Container>

      <VTSPreFooterContact />
    </PageLayout>
  );
};

export default VTSContactTemplate;
