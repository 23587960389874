export interface VTSCustomerJourneySectionProps {
  pageId: string;
  showCardNumber: boolean;
  usedInPage: CustomerJourneyPage;
}

export enum CustomerJourneyPage {
  Services = "services",
  Contact = "contact",
}
