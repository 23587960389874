import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSMapComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: "relative",
      height: theme.spacing(36),
      width: "100%",
      marginBottom: theme.spacing(7),
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
    },
    "green-tone": {},
    "map-container": {
      height: theme.spacing(36),
      width: "100%",
      marginBottom: theme.spacing(7),
      "& .leaflet-pane": {
        zIndex: 199,
      },
      "& .leaflet-top": {
        zIndex: 199,
      },
      "& .leaflet-bottom": {
        zIndex: 199,
      },
      "& .leaflet-tile-pane": {
        "-webkit-filter": "hue-rotate(10deg)",
        filter: "hue-rotate(10deg)",
      },
      "& .leaflet-marker-icon": {
        width: `${theme.spacing(6)} !important`,
        height: `${theme.spacing(6)} !important`,
        border: `2px solid ${theme.palette.grey[300]}`,
        borderRadius: "50%",
        padding: theme.spacing(0.9),
        "&::after": {
          content: "''",
          position: "absolute",
          left: "38%",
          width: 0,
          height: 0,
          clear: "both",
          top: "96%",
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: `15px solid #fff`,
        },
        "&::before": {
          content: "''",
          position: "absolute",
          left: "38%",
          width: 0,
          height: 0,
          clear: "both",
          top: "100%",
          borderLeft: "10px solid transparent",
          borderRight: "10px solid transparent",
          borderTop: `15px solid ${theme.palette.grey[300]}`,
        },
      },
    },
    overlay: {
      position: "absolute",
      top: theme.spacing(4),
      borderRadius: theme.spacing(0),
      zIndex: 199,
      left: theme.spacing(10),
      width: theme.spacing(25),
      padding: theme.spacing(4),
      boxShadow: `0 0 5px ${theme.palette.secondary.light}`,
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    unikoelnLogo: {
      borderRadius: "10% !important",
    },
    mapDescription: {
      marginTop: theme.spacing(1.5),
    },
  })
);
