import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { nanoid } from "nanoid";
import React, { useEffect, useMemo } from "react";
import useInView from "react-cool-inview";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import VTSSlickArrowBack from "../../01-atoms/VTSSlickArrowBack/VTSSlickArrowBack";
import VTSSlickArrowForward from "../../01-atoms/VTSSlickArrowForward/VTSSlickArrowForward";
import { VTSHistoryCarouselProps } from "./VTSHistoryCarouselInterfaces";
import { useVTSHistoryCarouselStyles } from "./VTSHistoryCarouselStyles";

const VTSHistoryCarousel = (props: VTSHistoryCarouselProps): JSX.Element => {
  const classes = useVTSHistoryCarouselStyles();
  const theme = useTheme();
  const xsViewport = useMediaQuery(theme.breakpoints.only("xs"));
  const smViewport = useMediaQuery(theme.breakpoints.only("sm"));
  const mdViewport = useMediaQuery(theme.breakpoints.only("md"));
  let numberOfSlides = 4;

  if (xsViewport) {
    numberOfSlides = 1;
  }
  if (smViewport) {
    numberOfSlides = 2;
  }
  if (mdViewport) {
    numberOfSlides = 3;
  }

  const { observe, inView, unobserve } = useInView<HTMLDivElement>({
    threshold: 1,
  });

  const sliderRef = React.useRef<Slider>(null);

  useEffect(() => {
    return () => {
      unobserve();
    };
  }, [unobserve]);

  useEffect(() => {
    if (inView === true) {
      sliderRef.current?.slickPlay();
    }
    if (inView === false) {
      sliderRef.current?.slickPause();
    }
  }, [inView]);

  const settings: Settings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: numberOfSlides,
    slidesToScroll: 1,
    nextArrow: <VTSSlickArrowForward />,
    prevArrow: <VTSSlickArrowBack />,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    pauseOnHover: true,
  };

  const milestones = useMemo(
    () =>
      props.milestones.map((milestone, index) => {
        return (
          <Box key={nanoid()} className={classes.milestone}>
            <Box>
              <Typography
                variant="body2"
                className={clsx(classes.text, classes.year)}
              >
                {milestone?.date.getFullYear().toString()}
              </Typography>

              <Typography
                variant="body1"
                component="div"
                className={classes.text}
              >
                <strong>
                  <SanitizeHTML html={milestone?.description} />
                </strong>
              </Typography>
            </Box>

            <Box className={classes.historyWrapperShapes}>
              <Box
                className={
                  // eslint-disable-next-line no-nested-ternary
                  index === 0
                    ? clsx(classes.historyLine, classes.firstHistoryLine)
                    : index + 1 === props.milestones.length
                    ? clsx(classes.historyLine, classes.lastHistoryLine)
                    : classes.historyLine
                }
              />

              <Box className={classes.historyDot} />
            </Box>
          </Box>
        );
      }),
    [classes, props.milestones]
  );

  return (
    <div className={classes.wrapper} ref={observe}>
      <Slider {...settings} className={classes.slider} ref={sliderRef}>
        {milestones}
      </Slider>
    </div>
  );
};

export default VTSHistoryCarousel;
