import { Box, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { dmYToYmd } from "../../../helpers/general/handleDate";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import VTSHistoryCarousel from "../../02-molecules/VTSHistoryCarousel/VTSHistoryCarousel";
import { Milestone } from "../../02-molecules/VTSHistoryCarousel/VTSHistoryCarouselInterfaces";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";
import { VTSHistoryProps } from "./VTSHistoryInterfaces";
import { useVTSHistoryStyles } from "./VTSHistoryStyles";

const VTSHistory = (props: VTSHistoryProps): JSX.Element => {
  const classes = useVTSHistoryStyles();
  const pageData = useSinglePage(props.pageId);
  const titleRaw = pageData?.pageFields?.aboutUs?.history?.title || "";
  const description = pageData?.pageFields?.aboutUs?.history?.description || "";

  const milestones = useMemo(
    () => pageData?.pageFields?.aboutUs?.history?.milestones || [],
    [pageData?.pageFields?.aboutUs?.history?.milestones]
  );

  const mappedMilestone: Milestone[] = useMemo(
    () =>
      milestones.reduce((result: Milestone[], milestone) => {
        if (milestone && milestone.description && milestone.date) {
          result.push({
            description: milestone.description,
            date: dmYToYmd(milestone.date),
          });
        }
        result.sort(
          (a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()
        );

        return result;
      }, []),
    [milestones]
  );

  return (
    <>
      <VTSUnderlinedTitle
        titleRaw={titleRaw}
        typographyProps={{ variant: "h2", className: classes.title }}
      />

      <Box>
        <VTSHistoryCarousel milestones={mappedMilestone} />
      </Box>

      <Typography variant="body1" component="div">
        <SanitizeHTML html={description} />
      </Typography>
    </>
  );
};

export default VTSHistory;
