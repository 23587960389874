import { TitlePosition } from "./TitlePosition";
import { WordpressImageInformation } from "./WordPress/WordpressImageInformation";

export interface TeamMember {
  id: string;
  title: string;
  titlePosition: TitlePosition;
  firstName: string;
  lastName: string;
  position: string;
  linkedInLink?: string;
  xingLink?: string;
  avatar?: WordpressImageInformation;
}
