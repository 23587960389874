import { Container, Grid, Typography } from "@material-ui/core";
import { useLocation } from "@reach/router";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { isBrowserEnvironment } from "../../../helpers/clientSide/isBrowserEnvironment";
import { mapWpImageInformation } from "../../../helpers/clientSide/WordPress/mapWpImageInformation";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import { useWpVitisSettings } from "../../../queries/wpVitisSettings/useWpVitisSettings";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import VTSCardWithBackground from "../../01-atoms/VTSCardWithBackground/VTSCardWithBackground";
import { InfoPosition } from "../../01-atoms/VTSCardWithBackground/VTSCardWithBackgroundInterfaces";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";
import {
  ContactOptionType,
  VTSPageHeaderProps,
} from "./VTSPageHeaderInterfaces";
import { useVTSPageHeaderStyles } from "./VTSPageHeaderStyles";

const VTSPageHeader = (props: VTSPageHeaderProps): JSX.Element => {
  const { t } = useTranslation();
  const classes = useVTSPageHeaderStyles();

  // DATA
  const headerData = useSinglePage(props.pageId)?.pageFields?.header;

  const titleRaw = headerData?.title;
  const descriptionRaw = headerData?.description;

  const underlinedTitle = titleRaw && (
    <VTSUnderlinedTitle
      titleRaw={titleRaw}
      typographyProps={{ variant: "h1", className: classes.title }}
      component="h1"
    />
  );

  const description = descriptionRaw && <SanitizeHTML html={descriptionRaw} />;

  const phoneNumber =
    useWpVitisSettings()?.vitisFields?.contact?.landlinePhoneNumber;
  const eMailAddress = useWpVitisSettings()?.vitisFields?.contact?.emailAddress;

  // LINKING CARDS
  const linkCardsData = headerData?.linkCards;

  const location = useLocation();

  // MAPS
  const mapContactClasses: { [key in ContactOptionType]: string } = {
    [ContactOptionType.Telephone]: `${classes.telephone}`,
    [ContactOptionType.Email]: `${classes.email}`,
  };

  const mapContactType: { [key in ContactOptionType]: string } = {
    [ContactOptionType.Telephone]: `${t("pageHeader.imprint.telephone")}:`,
    [ContactOptionType.Email]: `${t("pageHeader.imprint.email")}:`,
  };

  const mapContactData: { [key in ContactOptionType]: string } = {
    [ContactOptionType.Telephone]: phoneNumber || "",
    [ContactOptionType.Email]: eMailAddress || "",
  };

  const mapOnClick: { [key in ContactOptionType]: VoidFunction } = {
    [ContactOptionType.Telephone]: () => {
      if (isBrowserEnvironment()) {
        window.open(`tel:${mapContactData[ContactOptionType.Telephone]}`);
      }
    },
    [ContactOptionType.Email]: () => {
      if (isBrowserEnvironment()) {
        window.open(`mailto:${mapContactData[ContactOptionType.Email]}`);
      }
    },
  };

  return (
    <Grid className={clsx(classes.wrapper, props.wrapperClass)}>
      <Container className={clsx(classes.textWrapper, "noMargin")}>
        {underlinedTitle}

        <Typography component="div" className={classes.description}>
          {description}
        </Typography>
      </Container>

      {linkCardsData && (
        <Container className="noMargin">
          <Grid
            container={true}
            className={classes.linkCardsWrapper}
            spacing={1}
          >
            {linkCardsData?.map((card) => {
              return (
                <Grid
                  item={true}
                  key={card?.title}
                  className={classes.card}
                  xs={12}
                  md={4}
                >
                  <VTSCardWithBackground
                    title={card?.title || ""}
                    infoPosition={InfoPosition.Left}
                    arrowRotated={false}
                    showContactInfo={card?.showContactInformation || false}
                    height="17rem"
                    backgroundImage={mapWpImageInformation(
                      card?.backgroundImage
                    )}
                    linkTarget={location.origin + card?.link || ""}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      )}

      {props.showContactOptions === true && (phoneNumber || eMailAddress) && (
        <Grid className={classes.info}>
          {Object.values(ContactOptionType).map((contactOption) => (
            <Grid
              key={contactOption}
              className={mapContactClasses[contactOption]}
            >
              <Typography variant="body1">
                {mapContactType[contactOption]}
              </Typography>

              <Typography
                className={classes.infoItemDetails}
                variant="body1"
                onClick={mapOnClick[contactOption]}
              >
                <strong>{mapContactData[contactOption]}</strong>
              </Typography>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default VTSPageHeader;
