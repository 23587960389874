import { Grid } from "@material-ui/core";
import React from "react";
import { TeamMember } from "../../../interfaces/TeamMember";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import { mapSingleWpTeamMemberToTeamMember } from "../../../queries/wpTeamMembers/mapSingleWpTeamMemberToTeamMember";
import VTSTeamMemberCard from "../../02-molecules/VTSTeamMemberCard/VTSTeamMemberCard";
import { VTSTeamMemberGridProps } from "./VTSTeamMemberGridInterfaces";
import { useVTSTeamMemberGridStyles } from "./VTSTeamMemberGridStyles";

const VTSTeamMemberGrid = (props: VTSTeamMemberGridProps): JSX.Element => {
  const classes = useVTSTeamMemberGridStyles();

  const page = useSinglePage(props.pageId);
  const wpTeamMembers = page?.pageFields?.aboutUs?.teamMembers || [];

  const teamMembers: TeamMember[] = wpTeamMembers.map(
    (currentTeamMemberObject) => {
      const wpTeamMember = currentTeamMemberObject?.teamMember;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return mapSingleWpTeamMemberToTeamMember(wpTeamMember!);
    }
  );

  return (
    <Grid container={true} spacing={3} className={classes.wrapper}>
      {teamMembers.map((teamMember) => {
        return (
          <Grid key={teamMember.id} item={true} xs={12} md={6} lg={4}>
            <VTSTeamMemberCard teamMember={teamMember} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default VTSTeamMemberGrid;
