import { Box, Grid, Typography } from "@material-ui/core";
import { isBrowserEnvironment } from "@nvon/baseline";
import React from "react";
import { useTranslation } from "react-i18next";
import { mapWpImageInformation } from "../../../helpers/clientSide/WordPress/mapWpImageInformation";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import { useWpVitisSettings } from "../../../queries/wpVitisSettings/useWpVitisSettings";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import WordPressImage from "../../01-atoms/WordPressImage/WordPressImage";
import { VTSContactBoxProps } from "./VTSContactBoxInterfaces";
import { useVTSContactBoxStyles } from "./VTSContactBoxStyles";

const VTSContactBox = (props: VTSContactBoxProps): JSX.Element => {
  const classes = useVTSContactBoxStyles();
  const { t } = useTranslation();

  // Contact info
  const infoData = useWpVitisSettings()?.vitisFields;
  const address = infoData?.address;
  const contact = infoData?.contact;

  const handWrittenSlogan = infoData?.handwrittenSlogan;
  const street = `${address?.street} ${address?.streetNumber}`;
  const city = `${address?.postalCode} ${address?.city}`;
  const telephone = contact?.landlinePhoneNumber;
  const mobile = contact?.mobilePhoneNumber;
  const email = contact?.emailAddress;

  // Images
  const pageData = useSinglePage(props.pageId);
  const contactImages = pageData?.pageFields?.contact?.images;

  const renderedImages = contactImages?.map((contactImage, index) => {
    const imageData = mapWpImageInformation(contactImage);
    const renderedImage = imageData && (
      <WordPressImage imageInformation={imageData} imageClass={classes.image} />
    );

    return index === 0 ? (
      <Box className={classes.landscapeImage} key={contactImage?.title}>
        {renderedImage}
      </Box>
    ) : (
      <Box className={classes.portraitImage} key={contactImage?.title}>
        {renderedImage}
      </Box>
    );
  });

  // Telephone, mobile and email external links handlers
  const telephoneHandler = () => {
    if (isBrowserEnvironment()) {
      return window.open(`tel:${telephone}`);
    }
    return null;
  };

  const mobileHandler = () => {
    if (isBrowserEnvironment()) {
      return window.open(`tel:${mobile}`);
    }
    return null;
  };

  const emailHandler = () => {
    if (isBrowserEnvironment()) {
      return window.open(`mailto:${email}`);
    }
    return null;
  };

  return (
    <Grid container={true} spacing={3} className={classes.wrapper}>
      <Grid
        item={true}
        container={true}
        xs={12}
        md={7}
        className={classes.leftColumn}
      >
        {renderedImages && renderedImages[0]}

        <Box className={classes.slogan}>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.handWrittenSlogan}
          >
            <SanitizeHTML html={handWrittenSlogan || ""} />
          </Typography>
        </Box>

        <Box className={classes.contactInfo}>
          <Box className={classes.address}>
            <Typography variant="body2" color="textSecondary">
              <strong>{t("footer.vitisGmbH")}</strong>
            </Typography>

            <Typography variant="body2" color="textSecondary">
              {street}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              {city}
            </Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              <strong>{t("footer.contact")}</strong>
            </Typography>

            <Typography
              variant="body2"
              color="textSecondary"
              onClick={telephoneHandler}
              className={classes.link}
            >
              {`${t("footer.tel")}: `}

              {telephone}
            </Typography>

            {mobile && (
              <Typography
                variant="body2"
                color="textSecondary"
                onClick={mobileHandler}
                className={classes.link}
              >
                {`${t("footer.mobile")}: `}

                {mobile}
              </Typography>
            )}

            <Typography
              variant="body2"
              color="textSecondary"
              onClick={emailHandler}
              className={classes.link}
            >
              {email}
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid
        item={true}
        container={true}
        xs={12}
        md={5}
        className={classes.rightColumn}
      >
        {renderedImages && renderedImages[1]}
      </Grid>
    </Grid>
  );
};

export default VTSContactBox;
