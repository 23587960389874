import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { VTSCustomerJourneyCardProps } from "./VTSCustomerJourneyCardInterfaces";

export const VTSCustomerJourneyCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: (props: VTSCustomerJourneyCardProps) => ({
      "&.MuiCard-root": {
        borderRadius: "0.5em",
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.up("md")]: {
          // n * (numCards - cardNum), n = 1,2,3,...
          marginTop: theme.spacing(
            parseInt(`${4 * (props.numberOfCards - props.cardNumber)}`, 10)
          ),
        },
      },
    }),
    cardContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "flex-start",
    },
    title: {
      textAlign: "left",
      width: "100%",
      wordWrap: "break-word",
      [theme.breakpoints.down("xs")]: {
        marginBottom: theme.spacing(3),
      },
    },
    description: {
      width: "100%",
      wordWrap: "break-word",
    },
    numberContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: theme.spacing(2.25),
      height: theme.spacing(2.25),
      backgroundColor: theme.palette.background.default,
      borderRadius: "50%",
      marginLeft: theme.spacing(-0.75),
    },
  })
);
