import { useMemo } from "react";
import { DefaultsForOptionalProps } from "../../../helpers/clientSide/typeHelpers";
import { useMergedPropsNew } from "../../../hooks/useMergedPropsNew";
import {
  VTSServicesTabsProps,
  VTSServicesTabsViewModel,
} from "./VTSServicesTabsInterfaces";

const defaults: DefaultsForOptionalProps<VTSServicesTabsProps> = {
  headlineTag: "h2",
  progressBarInterval: 300,
  tabChangeInterval: 10000,
  pagePath: "",
  activeServiceHash: "",
};

export const useVTSServicesTabsViewModel = (
  props: VTSServicesTabsProps
): VTSServicesTabsViewModel => {
  /**
   * Should be 100 as defined by Material UI
   * See: https://material-ui.com/api/linear-progress/#props
   */
  const progressBarSize = 100;
  const mergedProps = useMergedPropsNew(defaults, props);
  return useMemo(
    () => ({
      progressBarSize,
      progressBarStep:
        progressBarSize /
        (mergedProps.tabChangeInterval / mergedProps.progressBarInterval),
      ...mergedProps,
    }),
    [mergedProps]
  );
};
