import { Box } from "@material-ui/core";
import React, { useMemo } from "react";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import VTSCustomerJourneyCard from "../../01-atoms/VTSCustomerJourneyCard/VTSCustomerJourneyCard";
import VTSCustomerJourney from "../../02-molecules/VTSCustomerJourney/VTSCustomerJourney";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";
import { VTSCustomerJourneySectionProps } from "./VTSCustomerJourneySectionInterfaces";
import { VTSCustomerJourneySectionStyles } from "./VTSCustomerJourneySectionStyles";

const VTSCustomerJourneySection = (
  props: VTSCustomerJourneySectionProps
): JSX.Element | null => {
  const classes = VTSCustomerJourneySectionStyles();

  const pageData = useSinglePage(props.pageId);

  const journey = pageData?.pageFields?.contact?.contactProcess;

  const projectJourneySteps = journey?.processSteps;
  const titleRaw = journey?.title;

  const underlinedTitle = titleRaw && (
    <VTSUnderlinedTitle
      titleRaw={titleRaw}
      typographyProps={{ variant: "h2", className: classes.title }}
      component="h3"
    />
  );

  const journeyCards = useMemo(
    () =>
      projectJourneySteps?.map((step, index) => {
        return (
          <VTSCustomerJourneyCard
            key={step?.processStep?.id || ""}
            title={step?.processStep?.title || ""}
            description={
              step?.processStep?.processStepFields?.description || ""
            }
            numberOfCards={projectJourneySteps?.length || 0}
            cardNumber={index + 1}
            showCardNumber={props.showCardNumber}
          />
        );
      }),
    [projectJourneySteps, props.showCardNumber]
  );
  return (
    <>
      <Box className={classes.wrapper}>{underlinedTitle}</Box>

      <VTSCustomerJourney numberOfCards={projectJourneySteps?.length || 0}>
        {journeyCards}
      </VTSCustomerJourney>
    </>
  );
};

export default VTSCustomerJourneySection;
