import { mapWpImageInformation } from "../../helpers/clientSide/WordPress/mapWpImageInformation";
import { TeamMember } from "../../interfaces/TeamMember";
import { mapWpTitlePositionToTitlePosition } from "./mapWpTitlePositionToTitlePosition";

export const mapSingleWpTeamMemberToTeamMember = (
  wpTeamMember: GatsbyTypes.WpTeamMemberFieldsFragment
): TeamMember => ({
  id: wpTeamMember.id,
  title: wpTeamMember.teamMemberFields?.title || "",
  titlePosition: mapWpTitlePositionToTitlePosition(
    wpTeamMember.teamMemberFields?.titlePosition || ""
  ),
  firstName: wpTeamMember.teamMemberFields?.firstName || "",
  lastName: wpTeamMember.teamMemberFields?.lastName || "",
  position: wpTeamMember.teamMemberFields?.position || "",
  linkedInLink: wpTeamMember.teamMemberFields?.linkedinLink || "",
  xingLink: wpTeamMember.teamMemberFields?.xingLink,
  avatar: mapWpImageInformation(wpTeamMember.teamMemberFields?.avatar),
});
