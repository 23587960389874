import Seo from "gatsby-plugin-wpgraphql-seo";
import React from "react";
import { DefaultWordPressPageProps } from "../../../interfaces/WordPress/DefaultWordPressPageProps";
import VTSNewsTemplate from "../../04-templates/VTSNewsTemplate/VTSNewsTemplate";

const VTSNewsPage = (props: DefaultWordPressPageProps): JSX.Element | null => {
  if (!props.pageContext.id) {
    return null;
  }

  return (
    <>
      {props.page && <Seo post={props.page} />}

      <VTSNewsTemplate pageId={props.pageContext.id} />
    </>
  );
};

export default VTSNewsPage;
