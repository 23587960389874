import { Container } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { PostTypeInProject } from "../../../../config/wordPressConfig";
import { DefaultPageProps } from "../../../interfaces/WordPress/DefaultPageInterfaces";
import { PageLayout } from "../../../layouts/pageLayout/PageLayout";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import VTSVerticalTitle from "../../01-atoms/VTSVerticalTitle/VTSVerticalTitle";
import VTSCircularLogoText from "../../02-molecules/VTSCircularLogoText/VTSCircularLogoText";
import VTSPublicationList from "../../02-molecules/VTSPublicationList/VTSPublicationList";
import VTSPageHeader from "../../03-organisms/VTSPageHeader/VTSPageHeader";
import VTSPreFooterContact from "../../03-organisms/VTSPreFooterContact/VTSPreFooterContact";
import { useVTSPublicationsTemplate } from "./VTSPublicationsTemplateStyles";

const VTSPublicationsTemplate = (props: DefaultPageProps): JSX.Element => {
  const classes = useVTSPublicationsTemplate();
  const publicationsVerticalTitle = useSinglePage(props.pageId)?.pageFields
    ?.header?.verticalTitle;
  const { t } = useTranslation();

  return (
    <PageLayout
      currentPostId={props.pageId}
      postType={PostTypeInProject.WpPage}
    >
      <Container className="noMargin">
        <VTSPageHeader
          pageId={props.pageId}
          showContactOptions={false}
          wrapperClass={classes.pageHeader}
        />

        <VTSCircularLogoText
          word={t("publications.publications")}
          numberOfRepetitions={4}
          radius={2}
        />
      </Container>

      <Container className={clsx("noMargin", "marginBottom")}>
        <VTSVerticalTitle title={publicationsVerticalTitle || ""} />

        <Container className="paddingX">
          <VTSPublicationList />
        </Container>
      </Container>

      <VTSPreFooterContact />
    </PageLayout>
  );
};

export default VTSPublicationsTemplate;
