import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSHistoryCarouselStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: "auto",
      padding: theme.spacing(2),
      [theme.breakpoints.only("md")]: {
        paddingLeft: theme.spacing(4),
      },
      [theme.breakpoints.up("lg")]: {
        width: "90%",
        paddingLeft: theme.spacing(6),
      },
    },
    slider: {
      "& .slick-arrow": {
        color: theme.palette.primary.contrastText,
      },
      "& .slick-track": {
        display: "flex !important",
      },
      "& .slick-slide": {
        height: "inherit !important",
      },
      "& .slick-slide > div": {
        height: "100%",
      },
    },
    milestone: {
      height: "100%",
      display: "flex !important",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    text: {
      textAlign: "center",
      margin: "0 auto",
      width: "90%",
    },
    year: {
      color: theme.palette.grey[300],
    },
    historyWrapperShapes: {
      marginTop: theme.spacing(1),
    },
    historyLine: {
      width: "100%",
      height: 0,
      borderBottom: `${theme.spacing(0.1)} solid ${
        theme.palette.primary.contrastText
      }`,
      position: "relative",
      top: theme.spacing(1),
      zIndex: -1,
    },
    firstHistoryLine: {
      width: "50%",
      left: "50%",
    },
    lastHistoryLine: {
      width: "50%",
    },
    historyDot: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: "50%",
      border: `${theme.spacing(0.2)} solid ${
        theme.palette.primary.contrastText
      }`,
      background: theme.palette.background.default,
      margin: "0 auto",
    },
  })
);
