import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const VTSCustomerJourneyStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        justifyContent: "space-between",
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    item: {
      position: "relative",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  })
);
