import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { VTSCustomerJourneyArrowProps } from "./VTSCustomerJourneyArrowInterfaces";

export const useVTSCustomerJourneyArrowStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrowWrapper: (props: VTSCustomerJourneyArrowProps) => ({
      position: "absolute",
      [theme.breakpoints.up("md")]: {
        top: 0,
        // n * (numCards - arrowNum), n = 1,2,3,...
        marginTop: theme.spacing(
          parseInt(`${4 * (props.numberOfCards - props.arrowNumber)}`, 10) + 6
        ),
        right: theme.spacing(-3),
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(1),
        right: theme.spacing(2),
        transform: "rotate(65deg) scaleY(-1)",
      },
      zIndex: 1,
    }),
  })
);
