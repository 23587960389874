/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable global-require */
import {
  Card,
  CardContent,
  NoSsr,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import "leaflet/dist/leaflet.css";
import React from "react";
import ReactDOMServer from "react-dom/server";
import { Template } from "../../../../config/wordPressConfig";
import { usePageForTemplate } from "../../../queries/wpPage/usePageForTemplate";
import UnikoelnLogo from "../../../resources/svg/unikoeln-logo.svg";
import VitisLogo from "../../../resources/svg/vitis-marker-pointer.svg";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import VTSUnderlinedTitle from "../../02-molecules/VTSUnderlinedTitle/VTSUnderlinedTitle";
import { useVTSMapComponentStyles } from "./VTSMapComponentStyles";

// http://leaflet-extras.github.io/leaflet-providers/preview/
const maps = {
  openstreetmap: "{s}.tile.openstreetmap.org",
  smooth: "tiles.stadiamaps.com/tiles/alidade_smooth",
  smoothDark: "tiles.stadiamaps.com/tiles/alidade_smooth_dark",
  light: "{s}.basemaps.cartocdn.com/light_all",
  lightNoText: "{s}.basemaps.cartocdn.com/light_nolabels",
};

const VTSMapComponent = (): JSX.Element => {
  const classes = useVTSMapComponentStyles();

  const theme = useTheme();
  const smallDevices = useMediaQuery(theme.breakpoints.down("sm"));

  const mapDetails = usePageForTemplate(Template.AboutUs)?.pageFields?.aboutUs
    ?.map;
  let Map;
  let Marker;
  let TileLayer;
  let RL;
  let vitisIcon;
  let unikoelnIcon;
  if (typeof window !== undefined) {
    vitisIcon = require("leaflet").divIcon({
      html: ReactDOMServer.renderToString(<VitisLogo />),
    });
    unikoelnIcon = require("leaflet").divIcon({
      html: ReactDOMServer.renderToString(<UnikoelnLogo />),
    });
    RL = require("react-leaflet");
    Map = RL.Map;
    Marker = RL.Marker;
    TileLayer = RL.TileLayer;
  }
  return (
    <NoSsr>
      <div className={classes.wrapper}>
        <Map
          center={!smallDevices ? [50.927, 6.8882] : [50.92, 6.91]}
          zoom={!smallDevices ? 13 : 12}
          scrollWheelZoom={false}
          className={classes["map-container"]}
        >
          <TileLayer
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url={`https://${maps.light}/{z}/{x}/{y}.png`}
          />

          <Marker position={[50.93645, 6.8882]} icon={vitisIcon} />

          <Marker position={[50.9236621, 6.9148548]} icon={unikoelnIcon} />
        </Map>

        <Card className={classes.overlay}>
          <CardContent>
            <VTSUnderlinedTitle
              titleRaw={mapDetails?.title ?? ""}
              typographyProps={{ variant: "h2", className: classes.title }}
              component="h2"
            />

            <Typography className={classes.mapDescription}>
              <SanitizeHTML html={mapDetails?.description || ""} />
            </Typography>
          </CardContent>
        </Card>
      </div>
    </NoSsr>
  );
};

export default VTSMapComponent;
