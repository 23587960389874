import { Container } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { PostTypeInProject } from "../../../../config/wordPressConfig";
import { DefaultPageProps } from "../../../interfaces/WordPress/DefaultPageInterfaces";
import { PageLayout } from "../../../layouts/pageLayout/PageLayout";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import VTSVerticalTitle from "../../01-atoms/VTSVerticalTitle/VTSVerticalTitle";
import VTSNewsGrid from "../../03-organisms/VTSNewsGrid/VTSNewsGrid";
import VTSPageHeader from "../../03-organisms/VTSPageHeader/VTSPageHeader";
import VTSPreFooterContact from "../../03-organisms/VTSPreFooterContact/VTSPreFooterContact";
import { useVTSNewsTemplate } from "./VTSNewsTemplateStyles";

const VTSNewsTemplate = (props: DefaultPageProps): JSX.Element => {
  const classes = useVTSNewsTemplate();
  const newsVerticalTitle = useSinglePage(props.pageId)?.pageFields?.header
    ?.verticalTitle;

  return (
    <PageLayout
      currentPostId={props.pageId}
      postType={PostTypeInProject.WpPage}
    >
      <Container className={clsx("noMargin", "marginBottom")}>
        <VTSPageHeader
          pageId={props.pageId}
          showContactOptions={false}
          wrapperClass={classes.pageHeader}
        />

        <VTSVerticalTitle title={newsVerticalTitle || ""} />

        <Container className={clsx("noMargin", "paddingX")}>
          {/* 0 means all the posts */}

          <VTSNewsGrid itemCount={0} wrapperClass={classes.newsGrid} />
        </Container>
      </Container>

      <VTSPreFooterContact />
    </PageLayout>
  );
};

export default VTSNewsTemplate;
