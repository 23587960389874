import { createStyles, lighten, makeStyles } from "@material-ui/core";

export const useVTSServicesTabsStyles = makeStyles((theme) => {
  const progressBarColor = theme.palette.primary.contrastText;

  return createStyles({
    tabsRoot: {
      marginBottom: theme.spacing(3),
      "& $tab:not(:last-child)": {
        marginRight: theme.spacing(1),
        [theme.breakpoints.down("md")]: {
          marginRight: 0,
        },
      },
    },
    tab: {
      color: theme.palette.text.primary,
      opacity: 0.5,
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
    },
    tabInnerWrapper: {
      display: "block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    selectedTab: {
      fontWeight: theme.typography.fontWeightBold,
    },
    indicator: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      height: 4,
    },
    progressBar: {
      maxWidth: 50,
      width: "100%",
      backgroundColor: lighten(progressBarColor, 0.65),
      // Disable the transition animation from 100 to 0 inside the progress bar
      '&[aria-valuenow="0"]': {
        "& > $progressBarInner": {
          transition: "none",
        },
      },
    },
    progressBarInner: {
      backgroundColor: progressBarColor,
    },
    slide: {
      display: "flex",
    },
    imageWrapper: {
      flex: "1 1 50%",
      maxHeight: "100%",
      borderRadius: 12,
      [theme.breakpoints.up("lg")]: {
        marginRight: theme.spacing(7),
      },
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginBottom: theme.spacing(3),
      },
      [theme.breakpoints.only("md")]: {
        width: "75%",
      },
      zIndex: 1,
    },
    image: {
      objectFit: "scale-down !important" as "scale-down",
    },
    infoWrapper: {
      flexDirection: "column",
    },
    title: {
      width: "100%",
    },
    description: {
      width: "100%",
    },
  });
});
