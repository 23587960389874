import { Theme } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { coverParent } from "../../../helpers/styles/coverParent";

export const useVTSPreFooterContact = makeStyles((theme: Theme) => {
  const flexRowCentered = {
    display: "flex" as const,
    flexDirection: "row" as const,
    justifyContent: "center" as const,
    textAlign: "center" as const,
  };

  return createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      backgroundColor: theme.palette.primary.main,
      paddingBottom: theme.spacing(9.3), // 150px
    },
    wrapperImage: {
      position: "relative",
      width: theme.spacing(9), // 144px
      height: theme.spacing(9), // 144px
      marginTop: theme.spacing(-3),
      marginBottom: theme.spacing(2.313), // 37px
    },
    image: {
      ...coverParent,
      borderRadius: "50%",
      zIndex: 1,
    },
    title: {
      textAlign: "center",
      fontWeight: theme.typography.fontWeightBold,
    },
    info: {
      ...flexRowCentered,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    email: {
      ...flexRowCentered,
      [theme.breakpoints.up("md")]: {
        paddingLeft: theme.spacing(2.5),
      },
    },
    telephone: {
      ...flexRowCentered,
    },
    infoItemDetails: {
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "transparent",
      },
      marginLeft: theme.spacing(1),
      textTransform: "lowercase",
      padding: 0,
    },
  });
});
