// transform string ddmmYYYY to date YYYYmmdd
export const dmYToYmd = (date: string): Date => {
  const ddmmyyyy = date.split("/");
  const yyyymmdd = new Date(
    parseInt(ddmmyyyy[2], 10),
    parseInt(ddmmyyyy[1], 10) - 1,
    parseInt(ddmmyyyy[0], 10)
  );
  return yyyymmdd;
};
