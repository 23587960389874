import { Container } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import { useLocation } from "@reach/router";
import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import { PostTypeInProject } from "../../../../config/wordPressConfig";
import { DefaultPageProps } from "../../../interfaces/WordPress/DefaultPageInterfaces";
import { PageLayout } from "../../../layouts/pageLayout/PageLayout";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import VTSVerticalTitle from "../../01-atoms/VTSVerticalTitle/VTSVerticalTitle";
import VTSNetworkSection from "../../03-organisms/VTSNetworkSection/VTSNetworkSection";
import VTSPageHeader from "../../03-organisms/VTSPageHeader/VTSPageHeader";
import VTSPreFooterContact from "../../03-organisms/VTSPreFooterContact/VTSPreFooterContact";
import { VTSServicesTabs } from "../../03-organisms/VTSServicesTabs/VTSServicesTabs";

const useVTSServicesStyles = makeStyles(() =>
  createStyles({
    scrollWrapper: {
      scrollMarginTop: "150px",
    },
  })
);

const VTSServicesTemplate = (props: DefaultPageProps): JSX.Element | null => {
  const classes = useVTSServicesStyles();
  const servicesVerticalTitle = useSinglePage(props.pageId)?.pageFields?.header
    ?.verticalTitle;
  const location = useLocation();
  const servicesTabsRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (location.hash !== "") {
      servicesTabsRef.current?.scrollIntoView({});
    }
  }, [location.hash, servicesTabsRef]);

  return (
    <PageLayout
      currentPostId={props.pageId}
      postType={PostTypeInProject.WpPage}
    >
      <Container className="noMargin">
        <VTSPageHeader pageId={props.pageId} showContactOptions={false} />
      </Container>

      <Container className="noMargin">
        <VTSVerticalTitle title={servicesVerticalTitle || ""} />

        <Container className="paddingX">
          <span ref={servicesTabsRef} className={classes.scrollWrapper}>
            <VTSServicesTabs
              pagePath={location.pathname}
              activeServiceHash={location.hash}
            />
          </span>
        </Container>

        <Container className={clsx("marginBottom", "paddingX")}>
          <VTSNetworkSection pageId={props.pageId} />
        </Container>
      </Container>

      <VTSPreFooterContact />
    </PageLayout>
  );
};

export default VTSServicesTemplate;
