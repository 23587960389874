import { Box, Card, CardContent, Typography } from "@material-ui/core";
import React from "react";
import { SanitizeHTML } from "../SanitizeHTML/SanitzeHTML";
import { VTSCustomerJourneyCardProps } from "./VTSCustomerJourneyCardInterfaces";
import { VTSCustomerJourneyCardStyles } from "./VTSCustomJourneyCardStyles";

const VTSCustomerJourneyCard = (
  props: VTSCustomerJourneyCardProps
): JSX.Element => {
  const classes = VTSCustomerJourneyCardStyles(props);
  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        {props.showCardNumber === true ? (
          <Box className={classes.numberContainer}>
            <Typography variant="body1" component="p">
              <strong>{props.cardNumber}</strong>
            </Typography>
          </Box>
        ) : null}

        <Typography variant="h5" className={classes.title}>
          {props.title}
        </Typography>

        <Typography variant="body1" className={classes.description}>
          <SanitizeHTML html={props.description || ""} />
        </Typography>
      </CardContent>
    </Card>
  );
};

export default VTSCustomerJourneyCard;
