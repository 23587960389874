import {
  Box,
  Grid,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { navigate } from "@reach/router";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import SwipeableViews from "react-swipeable-views";
import {
  SlideRendererCallback,
  SlideRenderProps,
  virtualize,
} from "react-swipeable-views-utils";
import { getPageUrlWithHashParameter } from "../../../helpers/clientSide/getPageUrlWithHashParameter";
import { replaceTagsWithFunctionComponent } from "../../../helpers/clientSide/replaceTagsWithFunctionComponent";
import { Service } from "../../../interfaces/Service";
import { useAllServices } from "../../../queries/wpServices/useAllServices";
import WordPressImage from "../../01-atoms/WordPressImage/WordPressImage";
import { VTSServicesTabPanel } from "../../02-molecules/VTSServicesTabPanel/VTSServicesTabPanel";
import { useVTSServicesTabsViewModel } from "./useVTSServicesTabsViewModel";
import {
  ServiceWithTabIndex,
  TabData,
  VTSServicesTabsProps,
} from "./VTSServicesTabsInterfaces";
import { useVTSServicesTabsStyles } from "./VTSServicesTabsStyles";

const EnhancedSwipeableViews = virtualize(SwipeableViews);

export const VTSServicesTabs = (props: VTSServicesTabsProps): JSX.Element => {
  const { progressBarInterval, progressBarStep, progressBarSize, headlineTag } =
    useVTSServicesTabsViewModel(props);
  const services: Service[] = useAllServices();

  const classes = useVTSServicesTabsStyles();
  const theme = useTheme();
  const isSmallViewPort = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeTabIndex, setActiveTabIndex] = useState<number>(1);

  const [barProgress, setBarProgress] = useState<number>(0);

  const timerRef = useRef<number>();

  const changeTab = useCallback(
    (newActiveTabIndex: number) => {
      if (newActiveTabIndex > services.length) {
        setActiveTabIndex(1);
      } else if (newActiveTabIndex < 1) {
        setActiveTabIndex(services.length);
      } else {
        setActiveTabIndex(newActiveTabIndex);
      }
    },
    [services.length]
  );

  const cancelTimer = () => clearInterval(timerRef.current);
  const servicesWithTabIndex: ServiceWithTabIndex[] = useMemo(() => [], []);

  const startTimer = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    timerRef.current = setInterval((prevState: number) => {
      if (barProgress >= progressBarSize) {
        const newTabIndex = activeTabIndex + 1;
        changeTab(newTabIndex);
        setBarProgress(0);
      } else {
        setBarProgress((oldProgress) => oldProgress + progressBarStep);
      }
    }, progressBarInterval);
  }, [
    progressBarInterval,
    barProgress,
    progressBarSize,
    activeTabIndex,
    changeTab,
    progressBarStep,
  ]);

  const resetTimer = useCallback(() => {
    cancelTimer();
    startTimer();
  }, [startTimer]);

  const handleChange = useCallback(
    (newActiveTabIndex: number) => {
      changeTab(newActiveTabIndex);
      setBarProgress(0);
      resetTimer();
    },
    [changeTab, resetTimer]
  );

  const getActiveServiceSlug = useCallback(
    (index: number) => {
      return (
        servicesWithTabIndex.find((service) => service.tabIndex === index)
          ?.slug || ""
      );
    },
    [servicesWithTabIndex]
  );

  // Change via tabs
  const handleChangeTabs = useCallback(
    (
      event: React.ChangeEvent<Record<string, unknown>>,
      newActiveTabIndex: number
    ) => {
      handleChange(newActiveTabIndex);

      const activeServiceSlug = getActiveServiceSlug(newActiveTabIndex);
      if (props.pagePath && activeServiceSlug !== "") {
        navigate(
          getPageUrlWithHashParameter(props.pagePath, activeServiceSlug)
        ).catch(() => {});
      }
    },
    [getActiveServiceSlug, handleChange, props.pagePath]
  );

  // Change via swiping
  const handleChangeIndexPanels = useCallback(
    (newActiveTabIndex: number) => {
      handleChange(newActiveTabIndex);

      let indexCheckedForEmptySlides = newActiveTabIndex;
      if (newActiveTabIndex > services.length) {
        indexCheckedForEmptySlides = 1;
      } else if (newActiveTabIndex < 1) {
        indexCheckedForEmptySlides = services.length;
      }

      const activeServiceSlug = getActiveServiceSlug(
        indexCheckedForEmptySlides
      );
      if (props.pagePath && activeServiceSlug !== "") {
        navigate(
          getPageUrlWithHashParameter(props.pagePath, activeServiceSlug)
        ).catch(() => {});
      }
    },
    [getActiveServiceSlug, handleChange, props.pagePath, services.length]
  );

  useEffect(() => {
    startTimer();
    return () => {
      cancelTimer();
    };
  }, [startTimer]);

  const { tabs, tabPanels }: TabData = useMemo(
    () =>
      services.reduce(
        (tabData, service, index) => {
          // Explicitly increment the index by 1 to have an empty panel at the beginning
          const tabIndex = index + 1;
          const tab = (
            <Tab
              key={service.id}
              label={service.title}
              classes={{
                root: classes.tab,
                wrapper: classes.tabInnerWrapper,
                selected: classes.selectedTab,
              }}
              disableRipple={true}
              value={tabIndex}
            />
          );
          servicesWithTabIndex.push({ ...service, tabIndex });
          tabData.tabs.push(tab);

          const title =
            service.title &&
            replaceTagsWithFunctionComponent(
              service.title,
              headlineTag,
              Typography
            );

          const description =
            service.description &&
            replaceTagsWithFunctionComponent(
              service.description,
              headlineTag,
              Typography
            );

          const tabPanel = (
            <VTSServicesTabPanel
              activeTabIndex={activeTabIndex}
              index={tabIndex}
              key={service.id}
            >
              {service.detailImage && (
                <WordPressImage
                  gatsbyImageProps={{ loading: "eager" }}
                  imageClass={classes.imageWrapper}
                  innerImageClass={classes.image}
                  imageInformation={service.detailImage}
                />
              )}

              <Grid container={true} className={classes.infoWrapper}>
                <Grid item={true}>
                  <Typography variant="h2" className={classes.title}>
                    <strong>{title}</strong>
                  </Typography>
                </Grid>

                <Grid item={true}>
                  <Typography variant="body1" className={classes.description}>
                    {description}
                  </Typography>
                </Grid>
              </Grid>
            </VTSServicesTabPanel>
          );
          tabData.tabPanels.push(tabPanel);
          return tabData;
        },
        {
          tabs: [],
          tabPanels: [],
        } as TabData
      ),
    [activeTabIndex, classes, headlineTag, services, servicesWithTabIndex]
  );

  const activeServiceSlug = useRef("");

  useEffect(() => {
    setBarProgress(0);
    const hashIndex = props.activeServiceHash?.indexOf("#");

    activeServiceSlug.current =
      hashIndex === -1 || hashIndex === undefined
        ? ""
        : props.activeServiceHash?.substring(hashIndex + 1) || "";

    const clickedTabIndex = servicesWithTabIndex.find(
      (service) => service.slug === activeServiceSlug.current
    )?.tabIndex;
    setActiveTabIndex(clickedTabIndex || 1);
  }, [props.activeServiceHash, servicesWithTabIndex]);

  const slideRenderer: SlideRendererCallback = useCallback(
    (slideRendererProps: SlideRenderProps) => {
      // Render empty div for virtualized empty slides at the end and the beginning of the tabs
      // to allow infinite swiping when reaching the end and the beginning and avoid a console error.
      return (
        tabPanels[slideRendererProps.index - 1] || (
          <div key={slideRendererProps.key} />
        )
      );
    },
    [tabPanels]
  );

  // The tab panels (aka slides) are displayed using "react-swipeable-views" to animate the transition from
  // one panel to the next.
  // To allow infinite (aka circular) swiping through the slides, meaning that once the end is reached
  // the first panel is shown again and vice versa, we use two extra empty panels at the beginning and end.
  // So our panels are: [empty, 1, 2, 3, ..., empty].
  // See the "slideRenderer" function for this implementation.
  return (
    <Box>
      <Tabs
        value={activeTabIndex}
        variant={isSmallViewPort ? "scrollable" : "standard"}
        centered={!isSmallViewPort}
        scrollButtons="on"
        onChange={handleChangeTabs}
        classes={{
          root: classes.tabsRoot,
          indicator: classes.indicator,
        }}
        TabIndicatorProps={{
          children: (
            <LinearProgress
              className={classes.progressBar}
              variant="determinate"
              value={barProgress}
              classes={{ bar: classes.progressBarInner }}
            />
          ),
        }}
      >
        {tabs}
      </Tabs>

      <EnhancedSwipeableViews
        onChangeIndex={handleChangeIndexPanels}
        index={activeTabIndex}
        containerStyle={{
          height: "100%",
          // Necessary to fix a bug causing the first transition animation to not happen in Chrome
          // See: https://github.com/oliviertassinari/react-swipeable-views/issues/599#issuecomment-657601754
          transition: "transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s",
        }}
        slideClassName={classes.slide}
        slideRenderer={slideRenderer}
        // "+ 2" for the first and last empty slides in order to allow infinite swiping
        slideCount={services.length + 2}
        enableMouseEvents={true}
        disableLazyLoading={true}
      />
    </Box>
  );
};
