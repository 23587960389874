import { Container, Typography } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { PostTypeInProject } from "../../../../config/wordPressConfig";
import { DefaultPageProps } from "../../../interfaces/WordPress/DefaultPageInterfaces";
import { PageLayout } from "../../../layouts/pageLayout/PageLayout";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import { SanitizeHTML } from "../../01-atoms/SanitizeHTML/SanitzeHTML";
import VTSVerticalTitle from "../../01-atoms/VTSVerticalTitle/VTSVerticalTitle";
import VTSPageHeader from "../../03-organisms/VTSPageHeader/VTSPageHeader";
import VTSPreFooterContact from "../../03-organisms/VTSPreFooterContact/VTSPreFooterContact";

const VTSImprintTemplate = (props: DefaultPageProps): JSX.Element | null => {
  const impressumData = useSinglePage(props.pageId);
  const impressumVerticalTitle =
    impressumData?.pageFields?.header?.verticalTitle;

  return (
    <PageLayout
      currentPostId={props.pageId}
      postType={PostTypeInProject.WpPage}
    >
      <Container className="noMargin">
        <VTSPageHeader pageId={props.pageId} showContactOptions={true} />
      </Container>

      <Container className="noMargin">
        <VTSVerticalTitle title={impressumVerticalTitle || ""} />

        <Container className={clsx("marginBottom", "paddingX")}>
          <Typography variant="body1" component="div">
            <SanitizeHTML html={impressumData?.content || ""} />
          </Typography>
        </Container>
      </Container>

      <VTSPreFooterContact />
    </PageLayout>
  );
};

export default VTSImprintTemplate;
