import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSNetworkSectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      justifyContent: "cetner",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        rowGap: theme.spacing(2),
      },
    },
    wrapperTitleDescription: {
      flexDirection: "column",
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
    },
    wrapperImage: {
      position: "relative",
      height: "100%",
      [theme.breakpoints.up("lg")]: {
        paddingLeft: theme.spacing(3),
      },
    },
    image: {
      margin: "0 auto",
      width: "100%",
      [theme.breakpoints.only("md")]: {
        width: "75%",
      },
    },
  })
);
