import { createStyles, makeStyles } from "@material-ui/core";
import { VTSServicesTabPanelStylesProps } from "./VTSServicesTabPanelInterfaces";

export const useVTSServicesTabPanelStyles = makeStyles((theme) =>
  createStyles({
    tabPanel: {
      display: (props: VTSServicesTabPanelStylesProps) =>
        props.isActivePanel ? "flex" : "none",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      flex: "1 1 auto",
      maxHeight: "100%",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
  })
);
