import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const VTSCustomerJourneySectionStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: theme.spacing(3),
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
    },
  })
);
