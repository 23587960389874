import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSNewsTemplate = makeStyles((theme: Theme) =>
  createStyles({
    pageHeader: {
      paddingBottom: theme.spacing(8),
    },
    newsGrid: {
      marginTop: theme.spacing(-8),
    },
  })
);
