import { Grid } from "@material-ui/core";
import React from "react";
import VTSCustomerJourneyArrow from "../../01-atoms/VTSCustomerJourneyArrow/VTSCustomerJourneyArrow";
import { VTSCustomerJourneyProps } from "./VTSCustomerJourneyInterfaces";
import { VTSCustomerJourneyStyles } from "./VTSCustomerJourneyStyles";

const VTSCustomerJourney = (props: VTSCustomerJourneyProps): JSX.Element => {
  const classes = VTSCustomerJourneyStyles();
  return (
    <Grid container={true} spacing={4} className={classes.container}>
      {React.Children.map(props.children, (child, index) => {
        const lastCard = index === props.numberOfCards - 1;
        return (
          <Grid item={true} xs={12} md={4} className={classes.item}>
            {child}

            {!lastCard && (
              <VTSCustomerJourneyArrow
                arrowNumber={index + 1}
                numberOfCards={props.numberOfCards}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default VTSCustomerJourney;
