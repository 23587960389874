import { ArrowBackIos } from "@material-ui/icons";
import React from "react";
import { CustomArrowProps } from "react-slick";

const VTSSlickArrowBack = (props: CustomArrowProps): JSX.Element => {
  /**
   * WARNING: React does not recognize the `slideCount`, `currentSlide` prop on a DOM element
   * WARNING TYPE: This is an unknown-prop warning
   * SOLUTION: The spread operator can be used to pull variables off props, and put the remaining props into a variable
   */
  const { currentSlide, slideCount, ...propsNeeded } = props;

  return <ArrowBackIos {...propsNeeded} />;
};

export default VTSSlickArrowBack;
