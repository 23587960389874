import { ReactNode } from "react";
import { Service } from "../../../interfaces/Service";

export interface VTSServicesTabsProps {
  pagePath?: string;
  activeServiceHash?: string;
  /**
   * The tag used to mark a headline in the backend
   */
  headlineTag?: keyof JSX.IntrinsicElements;
  /**
   * Automatic tab change interval length
   */
  tabChangeInterval?: number;
  /**
   *
   * Interval for updating the progress bar.
   * The smaller the value the smoother the progress bar fills up.
   */
  progressBarInterval?: number;
}

export interface VTSServicesTabsViewModel
  extends Required<VTSServicesTabsProps> {
  /**
   *
   * Should be 100 as defined by Material UI
   * See: https://material-ui.com/api/linear-progress/#props
   */
  progressBarSize: number;
  /**
   * Size of the steps by which the progress bar is incremented
   */
  progressBarStep: number;
}

export interface TabData {
  tabs: ReactNode[];
  tabPanels: ReactNode[];
}

export interface ServiceWithTabIndex extends Service {
  tabIndex: number;
}
