import clsx, { ClassValue } from "clsx";
import { mergeWith } from "lodash";
import { useMemo } from "react";
import {
  DefaultsForOptionalProps,
  RecursivePartial,
} from "../helpers/clientSide/typeHelpers";

const mergeFunction = (value: unknown, sourceValue: unknown, key: unknown) => {
  if (key === "className") {
    /**
     * While regular props should be overwritten, `className`s must be added.
     */
    return clsx(value as ClassValue, sourceValue as ClassValue);
  }
  return undefined;
};

/**
 * This function provides an approach to merging default values with passed props.
 * It has a certain mode of usage in mind, which is:
 *
 * - a component defines a set of props, some of which are optional. (PropsWithOptionalKeys)
 * - all optional props must be defined in the component's defaults.
 * - dynamic props (classNames, other runtime-dependant props) can be added at runtime.
 * - the resulting object will have no more optional keys.
 *
 * The merge function will perform a deep-merge, adding classNames on top of each other.
 *
 * @param defaultProps the default props for the component.
 * @param props passed by the user into the component.
 * @param dynamicProps runtime-dependant props, such as classNames
 */
export const useMergedPropsNew = <PropsWithOptionalKeys>(
  defaultProps: DefaultsForOptionalProps<PropsWithOptionalKeys>,
  props: PropsWithOptionalKeys,
  dynamicProps?: RecursivePartial<PropsWithOptionalKeys>
): Required<PropsWithOptionalKeys> =>
  useMemo(
    () =>
      mergeWith(
        {},
        defaultProps,
        props,
        dynamicProps,
        mergeFunction
      ) as unknown as Required<PropsWithOptionalKeys>,
    [defaultProps, dynamicProps, props]
  );
