import { Container, NoSsr } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { PostTypeInProject } from "../../../../config/wordPressConfig";
import { DefaultPageProps } from "../../../interfaces/WordPress/DefaultPageInterfaces";
import { PageLayout } from "../../../layouts/pageLayout/PageLayout";
import { useSinglePage } from "../../../queries/wpPage/useSinglePage";
import VTSVerticalTitle from "../../01-atoms/VTSVerticalTitle/VTSVerticalTitle";
import VTSHistory from "../../03-organisms/VTSHistory/VTSHistory";
import VTSMapComponent from "../../03-organisms/VTSMapComponent/VTSMapComponent";
import VTSPageHeader from "../../03-organisms/VTSPageHeader/VTSPageHeader";
import VTSPreFooterContact from "../../03-organisms/VTSPreFooterContact/VTSPreFooterContact";
import VTSTeamMemberGrid from "../../03-organisms/VTSTeamMemberGrid/VTSTeamMemberGrid";
import { useVTSAboutUsTemplate } from "./VTSAboutUsTemplateStyles";

const VTSAboutUsTemplate = (props: DefaultPageProps): JSX.Element => {
  const classes = useVTSAboutUsTemplate();
  const aboutUsVerticalTitle = useSinglePage(props.pageId)?.pageFields?.header
    ?.verticalTitle;

  return (
    <PageLayout
      currentPostId={props.pageId}
      postType={PostTypeInProject.WpPage}
    >
      <Container className="noMargin">
        <VTSPageHeader
          pageId={props.pageId}
          showContactOptions={false}
          wrapperClass={classes.pageHeader}
        />
      </Container>

      <Container className="noMargin">
        <VTSVerticalTitle title={aboutUsVerticalTitle || ""} />

        <Container className={clsx("noMargin", "marginBottom", "paddingX")}>
          <VTSTeamMemberGrid pageId={props.pageId} />
        </Container>
      </Container>

      <NoSsr>
        <VTSMapComponent />
      </NoSsr>

      <Container className={clsx("marginBottom", "noMargin", "paddingX")}>
        <VTSHistory pageId={props.pageId} />
      </Container>

      <VTSPreFooterContact />
    </PageLayout>
  );
};

export default VTSAboutUsTemplate;
