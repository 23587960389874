import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSHistoryStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: "1.663rem",
      margin: 0,
      "& > p": {
        margin: 0,
      },
    },
  })
);
