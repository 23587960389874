import { createStyles, makeStyles, Theme } from "@material-ui/core";

export const useVTSPageHeaderStyles = makeStyles((theme: Theme) => {
  const flexRowCentered = {
    display: "flex" as const,
    flexDirection: "row" as const,
    justifyContent: "center" as const,
    textAlign: "center" as const,
  };

  return createStyles({
    wrapper: {
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      borderRadius: theme.spacing(1.5),
      paddingBottom: theme.spacing(3),
    },
    textWrapper: {
      textAlign: "center",
      display: "row",
      flexDirection: "column",
      width: "75%",
      [theme.breakpoints.only("xs")]: {
        width: "100%",
      },
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      paddingTop: theme.spacing(4),
      "& > p": {
        margin: 0,
      },
    },
    description: {
      paddingBottom: theme.spacing(3),
      textAlign: "center",
    },
    linkCardsWrapper: {
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
      marginBottom: theme.spacing(3.375), // 54px
    },
    card: {
      width: "100%",
    },
    info: {
      ...flexRowCentered,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    email: {
      ...flexRowCentered,
      [theme.breakpoints.up("md")]: {
        paddingLeft: theme.spacing(2.5),
      },
    },
    telephone: {
      ...flexRowCentered,
    },
    infoItemDetails: {
      "&:hover": {
        cursor: "pointer",
      },
      paddingLeft: theme.spacing(1),
    },
  });
});
