import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { mapWpImageInformation } from "../../../helpers/clientSide/WordPress/mapWpImageInformation";
import { useWpVitisSettings } from "../../../queries/wpVitisSettings/useWpVitisSettings";
import { VTSAnimatedUnderline } from "../../01-atoms/VTSAnimatedUnderline/VTSAnimatedUnderline";
import WordPressImage from "../../01-atoms/WordPressImage/WordPressImage";
import { useVTSPreFooterContact } from "./VTSPreFooterContactStyles";
import { ContactOptionType } from "./VTSPreFooterInterfaces";

const VTSPreFooterContact = (): JSX.Element => {
  const classes = useVTSPreFooterContact();
  const { t } = useTranslation();

  // Data
  const contactData = useWpVitisSettings()?.vitisFields?.contact;
  const imageData = mapWpImageInformation(contactData?.contactAvatarImage);

  // Title
  const title = (
    <>
      {`${t("footer.preFooterTitle.part1")} `}

      <VTSAnimatedUnderline>
        {t("footer.preFooterTitle.part2")}
      </VTSAnimatedUnderline>

      {` ${t("footer.preFooterTitle.part3")}`}
    </>
  );

  // Maps
  const mapContactClasses: { [key in ContactOptionType]: string } = {
    [ContactOptionType.Telephone]: `${classes.telephone}`,
    [ContactOptionType.Email]: `${classes.email}`,
  };

  const mapContactType: { [key in ContactOptionType]: string } = {
    [ContactOptionType.Telephone]: `${t("footer.telephone")}:`,
    [ContactOptionType.Email]: `${t("footer.email")}:`,
  };

  const mapContactData: { [key in ContactOptionType]: string } = {
    [ContactOptionType.Telephone]: contactData?.landlinePhoneNumber || "",
    [ContactOptionType.Email]: contactData?.emailAddress || "",
  };

  const mapOnClick: { [key in ContactOptionType]: string } = {
    [ContactOptionType.Telephone]: `tel:${
      mapContactData[ContactOptionType.Telephone]
    }`,
    [ContactOptionType.Email]: `mailto:${
      mapContactData[ContactOptionType.Email]
    }`,
  };

  return (
    <Grid className={classes.wrapper}>
      <Grid className={classes.wrapperImage}>
        {imageData && (
          <WordPressImage
            imageInformation={imageData}
            imageClass={classes.image}
          />
        )}
      </Grid>

      <Typography variant="h2" className={classes.title}>
        {title}
      </Typography>

      <Grid className={classes.info}>
        {Object.values(ContactOptionType).map((contactOption) => (
          <Grid
            key={contactOption}
            className={mapContactClasses[contactOption]}
          >
            <Typography variant="body1">
              {mapContactType[contactOption]}
            </Typography>

            <Button
              href={mapOnClick[contactOption]}
              target="_blank"
              className={classes.infoItemDetails}
              color="inherit"
            >
              <Typography variant="body1">
                <strong>{mapContactData[contactOption]}</strong>
              </Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default VTSPreFooterContact;
